#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.selectable-table-row {
  cursor: pointer;
}

.form-row-wrapper>* {
  flex: 1 1 50%;
  padding: 1em;
}

.form-row-wrapper>.full-width {
  flex: 1 1 100%;
  padding: 1em;
}

.ui.labeled.input.phone-with-prefix > input {
  max-width: 65%;
}