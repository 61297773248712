body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.dragDropZone {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #ddd;
  border: 1px solid #888;
  min-width: 310px;
}

.dragDropZone > .sampleCard:nth-child(n+2) {
  margin-top: 10px;
}

.ui.segment.flexed, .ui.container.flexed {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f0f0;
  margin: 0;
}

.sampleDragDropArea {
  width: 200px;
  background-color: #ddd;
  border: '1px solid #aaa';
}

.sampleCard > .ui.card {
  padding: 10px;
}